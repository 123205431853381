import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'fdl-shimmers',
  templateUrl: './shimmers.component.html',
  styleUrls: ['./shimmers.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class FdlShimmersComponent implements OnInit {
  @Input() height: number;

  @Input() width: number;

  styling: Record<string, string>;

  constructor() {}

  ngOnInit() {
    this.styling = {
      height: `${this.height}px`,
    };

    if (this.width) {
      this.styling.width = `${this.width}px`;
    }
  }
}
