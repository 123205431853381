import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, EMPTY, finalize, map, Observable, of, Subscription, switchMap, throwError } from 'rxjs';
import { AuthService } from '../../core/services/auth.service';
import { RouterAuthService } from '../../core/services/router-auth.service';
import { TrackingService } from '../../core/services/tracking.service';
import { SignInQueryParams } from './sign-in-query-params.interface';
import { ExtendedOrgUser } from '../../core/models/extended-org-user.model';
import { SignInErrorState } from './sign-in-error-state.enum';
import { GoogleSignInService } from '../../core/services/google-sign-in.service';
import { RedirectionService } from '../../core/services/redirection.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TargetAppConfigService, TargetAppConfig } from '@fyle/target-app-config';
import cloneDeep from 'lodash-es/cloneDeep';
import { ButtonType } from '@fyle/model-shared';
import { AlertTypes } from '@fyle/alert';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit, OnDestroy {
  doesEmailExist: boolean;

  showNoEmailError: boolean;

  hideGoogleLogin: boolean;

  errorState: SignInErrorState;

  signInErrorState: typeof SignInErrorState = SignInErrorState;

  signInForm: FormGroup;

  showPlainPassword: boolean;

  showLoaderOnButton: boolean;

  isSignInApiInProgress: boolean;

  emailChangeSubscription: Subscription;

  buttonType = ButtonType;

  alertTypes: typeof AlertTypes = AlertTypes;

  private queryParams: SignInQueryParams;

  targetConfig: TargetAppConfig;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private window: Window,
    formBuilder: FormBuilder,
    private authService: AuthService,
    private routerAuthService: RouterAuthService,
    private trackingService: TrackingService,
    private googleSignInService: GoogleSignInService,
    private redirectionService: RedirectionService,
    private targetAppConfigService: TargetAppConfigService,
  ) {
    this.signInForm = formBuilder.group({
      email: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$'),
        ],
      ],
      password: [, [Validators.required]],
    });
  }

  private handleError(error: HttpErrorResponse) {
    switch (error.status) {
      case 400:
        this.router.navigate(['pending_verification']);
        break;

      case 422:
        this.router.navigate(['disabled']);
        break;

      case 401:
        this.showNoEmailError = true;
        this.errorState = SignInErrorState.UNAUTHORIZED;
        break;

      case 433:
        this.errorState = SignInErrorState.ACCOUNT_LOCKED;
        break;

      default:
        this.errorState = SignInErrorState.UNKNOWN_ERROR;
    }
  }

  private setExtendedOrgUser(): Observable<ExtendedOrgUser> {
    let eou$: Observable<ExtendedOrgUser>;
    const eou = this.authService.getEou();
    if (eou) {
      eou$ = of(eou);
    } else {
      eou$ = this.authService.refreshEou();
    }
    return eou$;
  }

  private resetErrorState() {
    this.errorState = null;
  }

  private registerEmailChange() {
    this.emailChangeSubscription = this.signInForm.statusChanges.subscribe((change) => {
      this.resetShowEmailError();
    });
  }

  basicAuthSignin() {
    if (!this.signInForm.valid) {
      return;
    }
    this.resetErrorState();
    this.isSignInApiInProgress = true;
    this.doesEmailExist = true;
    this.routerAuthService
      .basicSignin(this.signInForm.controls.email.value, this.signInForm.controls.password.value)
      .pipe(
        map((resp) => {
          this.trackingService.onSignin(this.signInForm.controls.email.value, {
            Asset: this.queryParams.asset,
            label: 'Email',
          });
        }),
        switchMap(() => this.setExtendedOrgUser()),
        finalize(() => {
          this.isSignInApiInProgress = false;
        }),
        catchError((error) => {
          this.handleError(error as HttpErrorResponse);
          return EMPTY;
        }),
      )
      .subscribe((resp) => {
        this.router.navigate(['switch_org'], {
          queryParams: {
            asset: this.queryParams.asset,
            redirect_uri: this.queryParams.redirect_uri,
            fyle_redirect_url: this.queryParams.fyle_redirect_url,
            org_id: this.queryParams.org_id,
          },
        });
      });
  }

  checkIfEmailExists() {
    this.resetShowEmailError();
    if (this.signInForm.controls.email.invalid) {
      return;
    }

    this.showLoaderOnButton = true;
    this.routerAuthService
      .checkEmailExists(this.signInForm.value.email)
      .pipe(
        finalize(() => {
          this.showLoaderOnButton = false;
        }),
        catchError((error) => {
          this.handleError(error);
          return throwError(() => error);
        }),
      )
      .subscribe((resp) => {
        if (resp.saml) {
          // IdP - Identity Provider URL (SAML)
          let idpUrl = resp.idp_url;
          if (this.queryParams.fyle_redirect_url) {
            const state: Partial<SignInQueryParams> = {
              fyle_redirect_url: this.queryParams.fyle_redirect_url,
            };

            if (this.queryParams.org_id) {
              state.org_id = this.queryParams.org_id;
            }
            const encodedState = this.window.btoa(JSON.stringify(state));
            idpUrl = `${idpUrl}&RelayState=${encodedState}`;
          }
          this.redirectionService.handleRedirection(idpUrl);
        } else {
          this.resetErrorState();
          this.doesEmailExist = true;
          this.signInForm.controls.email.disable();
        }
      });
  }

  resetShowEmailError() {
    this.showNoEmailError = false;
  }

  goToResetPassword() {
    const params: SignInQueryParams = cloneDeep(this.queryParams);
    params.email = this.signInForm.controls.email.value;
    this.router.navigate(['reset_password'], { queryParams: params });
  }

  onEmailChange() {
    this.doesEmailExist = false;
    this.resetErrorState();
    this.resetShowEmailError();
    this.signInForm.controls.email.enable();
    this.signInForm.controls.password.reset();
  }

  continueWithGoogle() {
    const url = this.googleSignInService.getGoogleSignInUrl(this.queryParams);
    this.window.location.replace(url);
  }

  ngOnDestroy() {
    this.emailChangeSubscription.unsubscribe();
  }

  ngOnInit() {
    this.targetConfig = this.targetAppConfigService.getTargetConfig();
    this.queryParams = this.activatedRoute.snapshot.queryParams as SignInQueryParams;
    this.registerEmailChange();
    const errorCode = this.queryParams.error_code;
    this.hideGoogleLogin = this.queryParams.hideGoogleLogin === 'yes';
    this.resetErrorState();

    if (this.queryParams.email) {
      this.signInForm.controls.email.setValue(this.queryParams.email);
    }

    if (errorCode) {
      if (errorCode === 'access_denied') {
        this.errorState = SignInErrorState.GOOGLE_ACCESS_DENIED;
        return;
      }

      this.doesEmailExist = true;
      this.signInForm.controls.email.disable();

      const error: Partial<HttpErrorResponse> = {
        status: parseInt(errorCode),
      };

      this.handleError(error as HttpErrorResponse);
      return;
    }

    if (this.authService.isLoggedIn()) {
      this.setExtendedOrgUser()
        .pipe(
          catchError((error) => {
            this.handleError(error);
            return throwError(() => error);
          }),
        )
        .subscribe((res) => {
          this.router.navigate(['switch_org'], {
            queryParams: {
              asset: this.queryParams.asset,
              fyle_redirect_url: this.queryParams.fyle_redirect_url,
              org_id: this.queryParams.org_id,
              redirect_uri: this.queryParams.redirect_uri,
            },
          });
        });
    }
  }
}
