<div class="password-rules-container">

  <ul class="rules tw-list-none">

    @for (error of errorMapping; track error) {
      <li class="condition-list">
        @if (!passwordStrength || !passwordStrength[error.type]) {
          <app-svg-sprite icon="check" classes="tw-h-14-px tw-w-14-px" class="icon icon-checkmark"></app-svg-sprite>
        }
        @if (passwordStrength && passwordStrength[error.type]) {
          <app-svg-sprite icon="cross" classes="tw-h-14-px tw-w-14-px" class="icon"></app-svg-sprite>
        }
        <span class="text">{{ error.message }}</span>
      </li>
    }

  </ul>

</div>
