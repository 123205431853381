<div class="fdl-modal-actions">
  <div class="action-container">
    @if (secondaryAction) {
      <fdl-button
        [buttonType]="secondaryAction.type"
        [defaultType]="'submit'"
        [label]="secondaryAction.label"
        [isLoading]="secondaryAction.loading"
        [loadingText]="secondaryAction.loadingText"
        [isDisabled]="secondaryAction.disabled"
        (buttonClick)="onSecondaryButtonClick()"
        [validateForm]="primaryAction.validateForm"
        [formToValidate]="primaryAction.formToValidate"
        [formElement]="primaryAction.formElement"
      ></fdl-button>
    }

    <ng-content></ng-content>

    @if (primaryAction) {
      <div [fdlTooltip]="primaryAction.tooltipText" [tooltipDirection]="'bottom'" [tooltipDisabled]="!primaryAction.tooltipVisible">
        <fdl-button
          [buttonType]="primaryAction.type"
          [defaultType]="'submit'"
          [label]="primaryAction.label"
          [isLoading]="primaryAction.loading"
          [loadingText]="primaryAction.loadingText"
          [isDisabled]="primaryAction.disabled"
          (buttonClick)="onPrimaryButtonClick()"
          [validateForm]="primaryAction.validateForm"
          [formToValidate]="primaryAction.formToValidate"
          [formElement]="primaryAction.formElement"
        ></fdl-button>
      </div>
    }

  </div>
</div>
