/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  ContentChild,
  DoCheck,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  SimpleChanges,
  ViewEncapsulation,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { Subject, debounceTime, filter, noop, takeUntil } from 'rxjs';
import {
  DropdownChangeEvent,
  DropdownLazyLoadEvent,
  DropdownModule,
  Dropdown,
  DropdownFilterOptions,
} from 'primeng/dropdown';
import { SvgSpriteComponent } from '@fyle/svg-sprite';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'fdl-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, DropdownModule, SvgSpriteComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FdlSelectComponent,
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class FdlSelectComponent implements OnInit, OnChanges, DoCheck, ControlValueAccessor, OnDestroy {
  /**
   * The options to be displayed in the dropdown. This can be either an array of strings or an array of objects.
   * If an array of objects is provided, ensure to set the `optionLabel` input to specify the object field to be displayed as the label in the dropdown.
   *
   * @example
   * // Using an array of strings:
   * options = ['Option1', 'Option2', 'Option3'];
   *
   * // Using an array of objects:
   * options = [{ id: '1', name: 'Option1' }, { id: '2', name: 'Option2' }];
   * optionLabel = 'name'; // Assuming you want to display the 'name' field in the dropdown
   */
  @Input({ required: true }) options: any = [];

  /**
   * The field name that needs to be displayed in the UI if options is an array of objects.
   * This input is required when passing an array of objects to `options`. It specifies which object property will be used as the display label in the dropdown.
   *
   * @example
   * // When using an array of objects for options:
   * options = [{ id: '1', value: 'USD' }, { id: '2', value: 'EUR' }];
   * optionLabel = 'value'; // Will display 'USD', 'EUR', etc., as the dropdown labels
   */
  @Input() optionLabel: string;

  /**
   * The field name to set value that needs to be assigned to the form control if options is an array of object.
   */
  @Input() optionValue: string;

  /**
   * If data is to be loaded lazily.
   */
  @Input() lazy: boolean = false;

  /**
   * Total count of options - used to determine if next chunk of data needs to be loaded.
   */
  @Input() totalCount: number;

  /**
   * This threshold represents the minimum number of items yet to be displayed in the dropdown's DOM.
   * When the actual number of items remaining to be added to the DOM falls below this threshold, it indicates that the user is nearing the end of the currently available options.
   * Consequently, the lazy load event is triggered to fetch and add more items to the dropdown, ensuring a continuous and seamless user experience without waiting for data to load at the very end.
   */
  @Input() itemsRemainingThreshold: number = 20;

  /**
   * Whether to load data in DOM on demand based on scroll, false by default.
   */
  @Input() virtualScroll: boolean = false;

  /**
   * Height of individual item in dropdown list in pixels, defaults to 32px.
   */
  @Input() virtualScrollItemSize: number = 32;

  /**
   * Whether to show search box in dropdown
   */
  @Input() showSearch: boolean = false;

  /**
   * Controls whether to enable custom filtering through an API for the dropdown options.
   * By default, this is set to false. When set to true, a filter event is emitted,
   * indicating that the component's consumer should handle the options filtering, typically via an API call.
   */
  @Input() useApiForSearch: boolean = false;

  /**
   * To define the field(s) to search against when searching
   */
  @Input() searchByKeys: string;

  /**
   * To define the placeholder for search box inside dropdown
   */
  @Input() dropdownSearchPlaceholder: string = 'Search';

  /**
   * To define the placeholder for search input field
   */
  @Input() placeholder: string;

  /**
   * Whether to display the first item as the label if no placeholder is defined and value is null.
   */
  @Input() autoDisplayFirst: boolean = false;

  /**
   * Style class for overlay panel of dropdown
   */
  @Input() panelStyleClass: string;

  /**
   * Style class for dropdown input element
   */
  @Input() styleClass: string;

  /**
   * Size of the dropdown input element: small | medium | large
   */
  @Input() size: 'small' | 'medium' | 'large' = 'medium';

  @Input() showClearIcon: boolean = false;

  /**
   * Callback to invoke when more data needs to be loaded, it emits the offset of the next chunk of data to be loaded.
   */
  @Output() lazyLoad = new EventEmitter<number>();

  /**
   * Callback to invoke when dropdown's overlay panel becomes visible.
   */
  @Output() dropdownPanelShow = new EventEmitter<void>();

  /**
   * This event is emitted when a filter action occurs, provided that useApiForSearch is set to true.
   * The consumer of this component should listen to this event to handle filtering logic,
   * especially when external API-based filtering is implemented.
   */
  @Output() search = new EventEmitter<Event>();

  @ViewChild('pDropdown') pDropdown: Dropdown;

  /**
   * Custom templates for dropdown items, search box and footer.
   */
  @ContentChild('itemTemplate') itemTemplateRef: TemplateRef<{
    $implicit: any;
  }> | null = null;

  @ContentChild('selectedItemTemplate') selectedItemTemplateRef: TemplateRef<{
    $implicit: any;
  }> | null = null;

  @ContentChild('footerTemplate') footerTemplateRef: TemplateRef<null> | null = null;

  dropdownControl: FormControl;

  ngControl: NgControl;

  private onModelChange: (value: any) => void = noop;

  private onModelTouched: () => void = noop;

  private lazyLoadEvents$ = new Subject<DropdownLazyLoadEvent>();

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private injector: Injector) {
    this.dropdownControl = new FormControl('');
  }

  focus() {
    this.pDropdown?.focus();
  }

  show() {
    this.pDropdown?.show();
  }

  writeValue(value: any) {
    this.dropdownControl.setValue(value);
  }

  searchHandler(event: Event, dropdownFilterOptions: DropdownFilterOptions) {
    if (this.useApiForSearch) {
      this.search.emit(event);
    } else {
      dropdownFilterOptions.filter(event);
    }
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.dropdownControl.disable();
    } else {
      this.dropdownControl.enable();
    }
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onModelTouched = fn;
  }

  onChange(event: DropdownChangeEvent) {
    this.onModelChange(event.value);
  }

  onBlur(event: Event) {
    if (!this.dropdownControl.touched) {
      this.onModelTouched();
    }
  }

  onPanelShow(): void {
    this.dropdownPanelShow.emit();
  }

  onLazyLoad(event: DropdownLazyLoadEvent) {
    this.lazyLoadEvents$.next(event);
  }

  clearFilter(event: Event) {
    this.dropdownControl.setValue(null);
    this.onModelChange(null);
    event.stopPropagation();
  }

  /**
   * If lazy loading is enabled, initialize the lazy loading subscription.
   * lazyLoad event is emitted once the last item in DOM passes the threshold of itemsRemainingThreshold. This is to ensure that the next chunk of data is loaded before the user reaches the end of the list.
   */
  initializeLazyLoading() {
    if (this.lazy) {
      this.lazyLoadEvents$
        .pipe(
          takeUntil(this.onDestroy$),
          debounceTime(300),
          filter((event) => {
            const currentSize = this.options.length;

            const isNearEndOfList = event.last >= currentSize - this.itemsRemainingThreshold;
            const hasMoreItemsToLoad = this.totalCount ? currentSize < this.totalCount : true;

            return isNearEndOfList && hasMoreItemsToLoad;
          }),
        )
        .subscribe((event) => {
          const nextoffset = this.options.length;
          this.lazyLoad.emit(nextoffset);
        });
    }
  }

  /**
   * @link https://github.com/primefaces/primeng/issues/10122.
   * The bug exists in latest version of primeng as well.
   * Converting array of strings to array of objects with keys as `id` and `value`
   */
  reformatOptionsAsObjectPairs() {
    if (this.showSearch && typeof this.options[0] === 'string') {
      const optionsObj = this.options.map((val: any) => {
        return {
          id: val,
          value: val,
        };
      });

      this.options = optionsObj;

      // Setting default value for optionLabel and searchByKeys
      this.optionLabel = this.optionValue = this.searchByKeys = 'value';
    }
  }

  ngOnInit(): void {
    // If the component is not used inside a form, the ngControl will be null.
    this.ngControl = this.injector.get(NgControl, null);
    this.initializeLazyLoading();
    this.reformatOptionsAsObjectPairs();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options && changes.options.previousValue !== changes.options.currentValue) {
      this.reformatOptionsAsObjectPairs();
    }
  }

  /**
   * When the parent form group is marked as touched, the same is not propagated to the subform.
   *
   * @link https://github.com/angular/angular/issues/45089
   * @link https://stackoverflow.com/questions/61566769/angular-controlvalueaccessor-and-markastouched
   */
  ngDoCheck() {
    if (this.ngControl?.touched && !this.dropdownControl.touched) {
      this.dropdownControl.markAllAsTouched();
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}
