import { Injectable } from '@angular/core';
import extend from 'lodash-es/extend';
import { AuthService } from './auth.service';
import { environment } from 'environments/environment';
import mixpanel from 'mixpanel-browser';

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  constructor(private authService: AuthService) {
    try {
      mixpanel.init(environment.MIXPANEL_PROJECT_TOKEN, {
        debug: false,
        track_pageview: true,
        persistence: 'localStorage',
      });
    } catch (e) {}
  }

  private identifyUser(email: string, properties?: Record<string, string>) {
    try {
      mixpanel.identify(email);
      if (properties) {
        mixpanel.people.set(properties);
      }
    } catch (e) {}
  }

  private getUserProperties() {
    const properties: Record<string, string> = {};
    const eou = this.authService.getEou();
    if (eou?.ou) {
      try {
        const distinctId = mixpanel.get_distinct_id();
        if (distinctId !== eou.us.email) {
          this.identifyUser(eou.us.email, {
            $name: eou.us.full_name,
            $email: eou.us.email,
          });
        }
      } catch (e) {}
      properties['User Email'] = eou.us.email;
      properties['User Org Name'] = eou.ou.org_name;
      properties['User Org ID'] = eou.ou.org_id;
      properties['User Full Name'] = eou.us.full_name;
      properties['User Org Currency'] = eou.org.currency;
    }
    return properties;
  }

  private eventTrack(action: string, properties: Record<string, any>) {
    if (properties.Asset !== 'webapp') {
      properties.Asset = 'webapp';
    }
    extend(properties, this.getUserProperties());
    try {
      mixpanel.track(action, properties);
    } catch (e) {}
  }

  // external APIs
  onSignup(email: string, properties: Record<string, any>) {
    this.identifyUser(email);
    this.eventTrack('Signup', properties);
  }

  // external APIs
  canSignup(email: string, properties: Record<string, any> = {}) {
    this.identifyUser(email);
    this.eventTrack('Signup Started', properties);
  }

  // new function name
  updateSegmentProfile(email: string, data: Record<string, string>) {
    try {
      const distinctId = mixpanel.get_distinct_id();
      if (distinctId !== email) {
        this.identifyUser(email, data);
      } else {
        mixpanel.people.set(data);
      }
    } catch (e) {}
  }

  // external APIs
  onSignin(email: string, properties: Record<string, any> = {}) {
    this.identifyUser(email);
    this.eventTrack('Signin', properties);
  }

  // signout event
  onSignOut(properties: Record<string, any> = {}) {
    this.eventTrack('Sign Out', properties);
  }

  // When user completes account setup journey
  setupComplete(properties: Record<string, any> = {}) {
    this.eventTrack('Setup Complete', properties);
  }

  // activated event
  activated(properties: Record<string, any> = {}) {
    this.eventTrack('Activated', properties);
  }

  // reset password event
  resetPassword(properties: Record<string, any> = {}) {
    this.eventTrack('Reset Password', properties);
  }

  onSwitchOrg(properties: Record<string, any>) {
    this.eventTrack('Switch Org', properties);
  }

  // email verified event
  emailVerified(properties: Record<string, any> = {}) {
    this.eventTrack('Email Verified', properties);
  }

  // Active organization display card tracking
  activeOrganizationDisplayCard(properties: Record<string, any> = {}) {
    this.eventTrack('Click on Active Organization Card under switch org page', properties);
  }

  deletedOldLocalStorageKeys(properties: Record<string, any> = {}) {
    this.eventTrack('Deleted Old Local Storage Keys', properties);
  }

  userAlreadyMigratedToNewLocalStorage(properties: Record<string, any> = {}) {
    this.eventTrack('User already on new local storage', properties);
  }

  migratedToNewLocalStorage(properties: Record<string, any> = {}) {
    this.eventTrack('Migrated to new local storage', properties);
  }
}
