import { Component, Input } from '@angular/core';

import { FdlButtonComponent } from '@fyle/button';
import { ButtonType } from '@fyle/model-shared';
import { ModalActionType } from './modal-action.model';
import { FdlTooltipDirective } from '@fyle/tooltip';
import { finalize, map, Observable } from 'rxjs';

@Component({
  selector: 'fdl-modal-actions',
  standalone: true,
  imports: [FdlButtonComponent, FdlTooltipDirective],
  templateUrl: './modal-actions.component.html',
  styleUrls: ['./modal-actions.component.scss'],
})
export class FdlModalActionsComponent {
  @Input() primaryAction: ModalActionType;

  @Input() secondaryAction: ModalActionType;

  buttonType = ButtonType;

  onPrimaryButtonClick() {
    const action$ = this.primaryAction.callbackMethod();
    if (action$ instanceof Observable) {
      this.primaryAction.loading = true;
      this.primaryAction.disabled = true;
      action$
        .pipe(
          finalize(() => {
            this.primaryAction.loading = false;
            this.primaryAction.disabled = false;
          }),
        )
        .subscribe();
    }
  }

  onSecondaryButtonClick() {
    const action$ = this.secondaryAction.callbackMethod();
    if (action$ instanceof Observable) {
      this.secondaryAction.loading = true;
      this.secondaryAction.disabled = true;
      action$
        .pipe(
          finalize(() => {
            this.secondaryAction.loading = false;
            this.secondaryAction.disabled = false;
          }),
        )
        .subscribe();
    }
  }
}
